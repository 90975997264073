import React from 'react';

import { Text } from 'theme-ui';
import Link from '../components/Link';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <Text as="h1">Hi from the second page</Text>
    <Text>Welcome to page 2</Text>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
);

export default SecondPage;
